<template>
    <div class="mune-page">
        <div class="mune-item">
            <div class="mune-item-frist flex-center between-box"
                 @click="isMineExpand = !isMineExpand">
                <span class="flex-center">
                  <img alt="" src="../../assets/img/home.png"/>我的
                </span>
                <i :class="isMineExpand ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"></i>
            </div>
            <div v-show="isMineExpand" class="mune-item-child">
                <router-link :class="{ active: navIndex[0] === 0 && navIndex[1] === 0 }"
                             class="mune-item-two" to="/home/index">我的
                </router-link>

                <router-link :class="{ active: navIndex[0] === 0 && navIndex[1] === 1 }"
                             :to="'/home/approval?t='+Date.now()" class="mune-item-two">
                    <el-badge :hidden="countApprovalData === 0" :value="countApprovalData">
                        <div>我审批的</div>
                    </el-badge>
                </router-link>
                <router-link :class="{ active: navIndex[0] === 0 && navIndex[1] === 6 }"
                             :to="'/home/todo?t='+Date.now()" class="mune-item-two">
                    <el-badge :hidden="daiban === 0" :value="daiban">
                        <div>我待办的</div>
                    </el-badge>
                </router-link>
                <router-link v-if="isProApproval" :class="{ active: navIndex[0] === 0 && navIndex[1] === 5 }"
                             :to="'/home/approvalPro?t='+Date.now()" class="mune-item-two">
                    <el-badge :hidden="countProjectData === 0" :value="countProjectData">
                        <div>项目信息变更</div>
                    </el-badge>
                </router-link>
                <router-link :class="{ active: navIndex[0] === 0 && navIndex[1] === 2 }"
                             :to="'/home/send?t='+Date.now()" class="mune-item-two">
                    我发起的
                </router-link>
                <router-link :class="{ active: navIndex[0] === 0 && navIndex[1] === 3 }"
                             :to="'/home/cc?t='+Date.now()" class="mune-item-two">
                    <el-badge :hidden="copyfor === 0" :value="copyfor">
                        <div>抄送我的</div>
                    </el-badge>
                </router-link>
                <router-link :class="{ active: navIndex[0] === 0 && navIndex[1] === 4 }"
                             class="mune-item-two" to="/home/systemMsg">系统消息
                </router-link>
                <!--<router-link-->
                <!--to="/home/dynamic"-->
                <!--class="mune-item-two"-->
                <!--:class="{ active: navIndex[0] === 0 && navIndex[1] === 5 }"-->
                <!--style="letter-spacing: 40px"-->
                <!--&gt;动态-->
                <!--</router-link>-->
            </div>
        </div>

        <div class="mune-item">
            <div class="mune-item-frist flex-center between-box"
                 @click="isSubExpand = !isSubExpand">
                <span class="flex-center">
                  <img alt="" src="../../assets/img/project.png"/>项目执行
                </span>
                <i :class="isSubExpand ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"></i>
            </div>
            <div v-show="isSubExpand" class="mune-item-child">
                <router-link :class="{ active: navIndex[0] === 4 && navIndex[1] === 8 }"
                             class="mune-item-two" to="/home/subMerchants">招商资料
                </router-link>
                <router-link :class="{ active: navIndex[0] === 4 && navIndex[1] === 9 }"
                             class="mune-item-two" to="/home/subSponsor">上游协议
                </router-link>
                <router-link v-if="isPayment" :class="{ active: navIndex[0] === 4 && navIndex[1] === 10 }"
                             class="mune-item-two" to="/home/payment">到款
                </router-link>
                <router-link :class="{ active: navIndex[0] === 4 && navIndex[1] === 11 }"
                             class="mune-item-two" to="/home/invoice">发票申请
                </router-link>
                <router-link :class="{ active: navIndex[0] === 4 && navIndex[1] === 26 }"
                             class="mune-item-two" to="/home/parity">供应商比价
                </router-link>
                <router-link :class="{ active: navIndex[0] === 4 && navIndex[1] === 12 }"
                             class="mune-item-two" to="/home/contract">下游协议
                </router-link>
                <router-link :class="{ active: navIndex[0] === 4 && navIndex[1] === 22 }"
                             class="mune-item-two" to="/home/scontract">特殊下游协议
                </router-link>
                <router-link :class="{ active: navIndex[0] === 4 && navIndex[1] === 13 }"
                             class="mune-item-two" to="/home/pay">项目付款
                </router-link>
                <router-link :class="{ active: navIndex[0] === 4 && navIndex[1] === 24}"
                             class="mune-item-two" to="/home/payLw">劳务付款
                </router-link>
                <router-link :class="{ active: navIndex[0] === 4 && navIndex[1] === 25}"
                             class="mune-item-two" to="/home/refund">退款
                </router-link>
                <router-link :class="{ active: navIndex[0] === 4 && navIndex[1] === 14 }"
                             class="mune-item-two" to="/home/otherPay">支票申请
                </router-link>
                <router-link :class="{ active: navIndex[0] === 4 && navIndex[1] === 15 }"
                             class="mune-item-two" to="/home/fpr">结项
                </router-link>
                <router-link :class="{ active: navIndex[0] === 4 && navIndex[1] === 16 }"
                             class="mune-item-two" to="/home/otherFpr">专项结项
                </router-link>
                <router-link :class="{ active: navIndex[0] === 4 && navIndex[1] === 17 }"
                             class="mune-item-two" to="/home/otherApproval">特殊审批
                </router-link>
                <router-link :class="{ active: navIndex[0] === 4 && navIndex[1] === 23 }"
                             class="mune-item-two" to="/home/subOtherSign">特殊签批
                </router-link>
                <router-link :class="{ active: navIndex[0] === 4 && navIndex[1] === 18 }"
                             class="mune-item-two" to="/home/advance">垫付申请
                </router-link>
                <!--<router-link to="/home/register" class="mune-item-two" :class="{active:navIndex[0] === 1 && navIndex[1] === 2}">用章登记</router-link>-->
            </div>
        </div>
        <div class="mune-item">
            <div class="mune-item-frist flex-center between-box"
                 @click="isXZExpand = !isXZExpand">
                <span class="flex-center">
                  <img alt="" src="../../assets/img/project.png"/>行政管理
                </span>
                <i :class="isXZExpand ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"></i>
            </div>
            <div v-show="isXZExpand" class="mune-item-child">
                <router-link :class="{ active: navIndex[0] === 5 && navIndex[1] === 1 }"
                             class="mune-item-two" to="/home/subApproval">行政审批
                </router-link>
                <router-link v-if="isSignApproval" :class="{ active: navIndex[0] === 5 && navIndex[1] === 3 }"
                             class="mune-item-two" to="/home/subSign">签批
                </router-link>
                <router-link v-if="isSignApproval" :class="{ active: navIndex[0] === 5 && navIndex[1] === 4 }"
                             class="mune-item-two" to="/home/subCouncil">理事会
                </router-link>
                <router-link :class="{ active: navIndex[0] === 5 && navIndex[1] === 2 }"
                             class="mune-item-two" to="/home/xzregister">用章登记
                </router-link>
                <router-link :class="{ active: navIndex[0] === 5 && navIndex[1] === 5 }"
                             class="mune-item-two" to="/home/businessStatistics">业务量统计
                </router-link>
                <!--<router-link to="/home/register" class="mune-item-two" :class="{active:navIndex[0] === 1 && navIndex[1] === 2}">用章登记</router-link>-->
            </div>
        </div>
        <div class="mune-item">
            <div class="mune-item-frist flex-center between-box"
                 @click="isProExpand = !isProExpand">
                <span class="flex-center">
                  <img alt="" src="../../assets/img/project.png"/>项目立项
                </span>
                <i :class="isProExpand ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"></i>
            </div>
            <div v-show="isProExpand" class="mune-item-child">
                <router-link :class="{ active: navIndex[0] === 1 && navIndex[1] === 1 }"
                             class="mune-item-two" to="/home/list">项目列表
                </router-link>

                <router-link v-if="financiaBook" :class="{ active: navIndex[0] === 1 && navIndex[1] === 2 }"
                             class="mune-item-two" to="/home/financialBook">财务台账
                </router-link>
                <router-link v-if="financiaBook" :class="{ active: navIndex[0] === 1 && navIndex[1] === 3 }"
                             class="mune-item-two" to="/home/to_statistics">到账统计
                </router-link>
                <!--<router-link to="/home/register" class="mune-item-two" :class="{active:navIndex[0] === 1 && navIndex[1] === 2}">用章登记</router-link>-->
            </div>
        </div>
        <div v-if="user.if_admin" class="mune-item">
            <div class="mune-item-frist flex-center between-box"
                 @click="isSysExpand = !isSysExpand">
                <span class="flex-center">
                  <img alt="" src="../../assets/img/xuehui.png"/>系统管理
                </span>
                <i :class="isSysExpand ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"></i>
            </div>
            <div v-show="isSysExpand" class="mune-item-child">
                <router-link :class="{ active: navIndex[0] === 2 && navIndex[1] === 1 }"
                             class="mune-item-two" to="/home/department">部门管理
                </router-link>
                <router-link :class="{ active: navIndex[0] === 2 && navIndex[1] === 4 }"
                             class="mune-item-two" to="/home/team">小组管理
                </router-link>
                <router-link :class="{ active: navIndex[0] === 2 && navIndex[1] === 2 }"
                             class="mune-item-two" to="/home/personnel">人员管理
                </router-link>
                <router-link :class="{ active: navIndex[0] === 2 && navIndex[1] === 3 }"
                             class="mune-item-two" to="/home/level">审批管理
                </router-link>
            </div>
        </div>
        <div class="mune-item">
            <div class="mune-item-frist flex-center between-box"
                 @click="isCompanyExpand = !isCompanyExpand">
                <span class="flex-center">
                    <img alt="" src="../../assets/img/xitong.png"/>企业管理
                </span>
                <i :class="isCompanyExpand ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"></i>
            </div>
            <div v-show="isCompanyExpand" class="mune-item-child">
                <router-link :class="{ active: navIndex[0] === 6 && navIndex[1] === 0 }"
                             class="mune-item-two" to="/home/company/upstreams">上游企业库
                </router-link>
                <router-link :class="{ active: navIndex[0] === 6 && navIndex[1] === 1 }"
                             class="mune-item-two" to="/home/company/downstreams">下游企业库
                </router-link>
                <router-link :class="{ active: navIndex[0] === 6 && navIndex[1] === 2 }"
                             class="mune-item-two" to="/home/company/cooperation">合作中心
                </router-link>
            </div>
        </div>
        <div class="mune-item">
            <div class="mune-item-frist flex-center between-box"
                 @click="isPassWordExpand = !isPassWordExpand">
                <span class="flex-center">
                  <img alt="" src="../../assets/img/xitong.png"/>密码修改
                </span>
                <i :class="isPassWordExpand ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"></i>
            </div>
            <div v-show="isPassWordExpand" class="mune-item-child">
                <router-link :class="{ active: navIndex[0] === 3 && navIndex[1] === 1 }"
                             class="mune-item-two" to="/home/passSet">密码修改
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
import config from "../../config/index";

export default {
    name: "muneDiv",
    inject: ['reload'], // 引入方法
    data() {
        return {
            index: [],
            isPayment: this.$store.state.user.department === "领导" || this.$store.state.user.department === "财务部",
            isMineExpand: this.navIndex[0] === 0,
            isSubExpand: this.navIndex[0] === 4,
            isXZExpand: this.navIndex[0] === 5,
            isProExpand: this.navIndex[0] === 1,
            isSysExpand: this.navIndex[0] === 2,
            isCompanyExpand: this.navIndex[0] === 6,
            isPassWordExpand: this.navIndex[0] === 3,
            financiaBook: false,
            isProApproval: this.$store.state.user.mid === config.zhennggehong,
            isSignApproval: this.$store.state.user.mid === config.chenjing,

        };
    },
    props: {
        navIndex: {
            type: Array,
        },
        countApprovalData: {
            type: Number,
        },
        countProjectData: {
            type: Number,
        },
        daiban: {
            type: Number,
        },
        copyfor: {
            type: Number,
        },
    },

    computed: {
        user() {
            return this.$store.state.user;
        },
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            this.financiaBook = this.user.jid === 2 || this.user.jid === 3 || this.user.did === 4
        }
    },
    watch: {
        navIndex() {
            this.isMineExpand = this.navIndex[0] === 0;
            this.isSubExpand = this.navIndex[0] === 4;
            this.isXZExpand = this.navIndex[0] === 5;
            this.isProExpand = this.navIndex[0] === 1;
            this.isSysExpand = this.navIndex[0] === 2;
            this.isCompanyExpand = this.navIndex[0] === 6;
            this.isPassWordExpand = this.navIndex[0] === 3;
        },
    },
};
</script>

<style lang="scss" scoped>
.mune-page {
    padding: 34px 0;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 0px;
    }

    .mune-item {
        .mune-item-frist {
            height: 72px;
            padding: 0 30px;
            position: relative;

            &.active {
                background: #3b77e7;

                &::before {
                    position: absolute;
                    top: 17px;
                    left: 0;
                    content: "";
                    width: 3px;
                    height: 38px;
                    background-color: #3bc1e7;
                }
            }

            span {
                color: #ececec;
                font-size: 24px;

                img {
                    width: 30px;
                    margin-right: 30px;
                }
            }

            i {
                font-size: 24px;
                color: #fff;
            }
        }

        .mune-item-child {
            background-color: #485372;

            .mune-item-two {
                display: block;
                width: 301px;
                height: 56px;
                line-height: 56px;
                padding-left: 89px;
                font-size: 20px;
                color: #ececec;
                position: relative;

                &.active {
                    background: #3b77e7;

                    &::before {
                        position: absolute;
                        top: 9px;
                        left: 0;
                        content: "";
                        width: 3px;
                        height: 38px;
                        background-color: #3bc1e7;
                    }
                }
            }
        }
    }
}
</style>
